<template>
  <div class="container">
    <div class="title">
      <h1>{{ noticeInfo.noticeTitle }}</h1>
      <div class="release-date">
        <span>发布时间：</span>
        <span>{{ noticeInfo.releaseTime|filterDate }}</span>
      </div>
    </div>
    <div class="tender-info">
        <div class="item">
          <label>公示开始时间</label>
          <span>{{ noticeInfo.timeBegin|filterTime }}</span>
        </div>
        <div class="item">
          <label>公示结束时间</label>
          <span>{{ noticeInfo.timeEnd|filterTime }}</span>
        </div>
      </div>
      <div
        class="content-text"
        v-html="noticeInfo.publicityContent"
      ></div>

      <FileList :fileList="noticeInfo.filePath.split(',')"></FileList>

      <!-- 表格 -->
      <!-- <el-table :data="noticeInfo.sectionList" style="width: 100%" stripe>
        <el-table-column prop="no" label="序号" width="120"></el-table-column>
        <el-table-column prop="name" label="标段名称"></el-table-column>
      </el-table> -->
  </div>
</template>

<script>
import FileList from '@/components/FileList/index.vue'
import indexApi from "@/api/index";
export default {
  components: {
    FileList
  },
  data() {
    return {
      noticeInfo: {
        noticeTitle: '这是标题这是标题这是标题'
      },
      dialogVisible: false,
    }
  },
  filters: {
    filterElectronicType(val) {
      if (val == 0) return "非电子标";
      if (val == 1) return "电子标";
    },
    filterTime(val) {
      return moment(val).format("YYYY-MM-DD HH:mm:ss");
    },
    filterDate(val) {
      return moment(val).format("YYYY-MM-DD");
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    async getDetail() {
      let {data} = await indexApi.getPublicityDetail({
        id: this.$route.query.id
      })
      this.noticeInfo = {...data}
    },
    handleClose() {
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
  .container {
    // 标题
    .title {
      text-align: center;
      padding: 20px 0;
      border-bottom: 1px dashed rgba(236, 236, 241, 1);
      h1 {
        color: #20201E;
        font-size: 22px;
        margin-bottom: 10px;
        font-weight: bold;
      }

      .release-date {
        color: #999999;
        font-size: 14px;
      }
    }
    // 招标信息
    .tender-info {
      margin-bottom: 20px;
      margin-top: 24px;
      border: 1px solid rgba(236, 236, 241, 1);
      border-right: 0;
      border-bottom: 0;
      top: -40px;
      background: #fff;
      box-shadow: 0 5px 10px 1px rgba(0, 0, 0, 0.05);
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 50%;
        font-size: 16px;
        color: rgba(73, 74, 76, 1);
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 50px;
        border-bottom: 1px solid rgba(236, 236, 241, 1);
        border-right: 1px solid rgba(236, 236, 241, 1);

        label {
          border-right: 1px dashed rgba(236, 236, 241, 1);
          color: #494A4C;
          font-weight: bold;
          flex: 1;
        }

        span {
          line-height: 20px;
          flex: 1;
          color: #20201E;
          white-space: wrap;
          a {
            color: #6C8BC6;
            white-space: wrap;
          }
        }

        label, span {
          padding-left: 24px;
        }
      }
    }
    .content-text {
      max-width: 1200px;
      overflow-x: auto;
    }
    // 表格
    .el-table {
      margin: 33px 0;

      /deep/ th {
        background-color: #F4F4F4;
        height: 48px;
        color: #333;
        text-align: left;
        padding-left: 20px;
        font-weight: bold;
      }

      .bid {
        color: rgba(243, 110, 49, 1);

        img {
          width: 16px;
        }
      }

      /deep/ tr {
        height: 40px;

        td {
          padding-left: 20px;
        }

        &:nth-child(odd) {
          // background: #ECECF1;
        }
      }
    }
  }
</style>